import React, { Component } from 'react'
import { Table } from 'reactstrap';
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helmet } from "react-helmet";
import { cap, pw, gambar } from '../params'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Alert from 'react-bootstrap/Alert'
import '../style/gold2.css'
import path from '../assets/img/gold2/path1.svg'
import img1 from '../assets/img/gold2/wed1.jpg'
import img2 from '../assets/img/gold2/wed2.jpg'
import img3 from '../assets/img/gold2/wed3.jpg'
import img4 from '../assets/img/gold2/wed4.jpg'
import music from '../assets/music/music2.aac'
import logoig from '../assets/img/gold2/logoig.svg'
import path3 from '../assets/img/gold1/path3.svg'
import groom from '../assets/img/gold1/rhobby.png'
import bride from '../assets/img/gold1/rovita.png'
import path2 from '../assets/img/gold1/path2.svg'
import bunga6 from '../assets/img/bunga6.png'
import logo from '../assets/img/logo.ico'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import post from '../params/post'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = ""
let id = 'widya-ardy'
let inisial_co = 'Ardy'
let inisial_ce = 'Widya'
let lengkap_co = 'Ardy Hermawan Amirudin'
let lengkap_ce = 'Widya Akmalinda'
let bapak_co = 'Bpk. H. Muhammad Amirudin'
let ibu_co = 'Ibu Hj. Muhrindawati'
let bapak_ce = "Bpk. Akmo Sidi "
let ibu_ce = "Ibu Indah Wahyuni"
let ig_co = "ardyhermawan31"
let ig_ce = "widyaakmalinda"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "12/20/2020"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://maps.app.goo.gl/N3Cne96FRkSN5wMFA"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MHVzMTZka3VlaGJmcXBtYjQxY2xkcXVsNXAgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.661085105068!2d114.00215231477767!3d-7.719464994435708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zN8KwNDMnMTAuMSJTIDExNMKwMDAnMTUuNiJF!5e0!3m2!1sid!2sid!4v1607223099284!5m2!1sid!2sid"
let brown = "#77411A"

export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()
    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      clicked: 0,
      sesi: 1
    }
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir} ${this.state.sesi}", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
              )
              if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
              } else {
                err.push('Koneksi Gagal')
              }

            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 1000
    });
    let acara = [
      [
        'Resepsi',
        'Minggu, 14 Februari 2021',
        <><div className="w-100 text-center">
          <b>
            Waroeng dan Cafe Arofah
          </b> <br />Jl. Manyar No.73 Slawu, Patrang, Jember
          </div></>,
        <>Sesi I : 12.00 WIB - 15.00 WIB <br />
      Sesi II : 15.00 WIB - 18.00 WIB
        </>
      ]
    ]
    this.setState({ acara: acara })
    var countDownDate = new Date("02/14/2021").getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })

    }, 1000);

  }
  play = () => {

    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();
    window.scrollTo({
      top: this.myRef.current.offsetTop,
      behavior: 'smooth'
    })
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('sectiongold24');
      elmnt.scrollIntoView();
    }, 1000)
    setTimeout(() => {
      AOS.refresh()
    }, 1100)

  }
  googleCalendar = () => {
    window.open(`https://calendar.google.com/calendar/r/eventedit?
text=Event%20Title
&details=Pernikahan%20Willy%20dan%20Jessica
&dates=20201010T100000/20201010T113000
&ctz=Indonesia%2FEast_Java&title=Pernikahan%20Willy%20dan%20Jessica
    `, '_blank')
  }
  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }

  render() {
    let slide = [
      "1 (1).jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
      "9.jpg",
      "10.jpg",
    ]
    let { clicked, acara, days, hours, minutes, seconds, hide, hadir, err, submitted, sesi, showrek } = this.state
    let slider = []
    slide.map(v => {
      slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
    })

    let story = [
      ['Modi sit est',
        'Quis excepturi porro totam sint earum quo nulla perspiciatis eius.',
        img1],
      ['Modi sit est',
        'Quis excepturi porro totam sint earum quo nulla perspiciatis eius.',
        img2],
      ['Modi sit est',
        'Quis excepturi porro totam sint earum quo nulla perspiciatis eius.',
        img3],
      ['Modi sit est',
        'Quis excepturi porro totam sint earum quo nulla perspiciatis eius.',
        img4]
    ]
    console.log(clicked)
    let query = this.useQuery().get('u');
    return (
      <>
        <Helm
          title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={slider[0]}
          url={`https://undanganku.me/${id}`}
        />
        <div id='gold2'>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw'
          }}>
            {
              this.useQuery().get('x') == "x" ? (<Float />) : false
            }

            <div className="jumbotrongold2 jumbotron-fluid mb-0 w-100" id='sectiongold21' style={{
              overflow: 'hidden', maxWidth: '100vw',
              position: 'relative',
              backgroundImage: `url('${pw(id, "modal.jpg")}')`
            }}>
              <div className={`path w-100 ${hide ? 'hide' : 'show'}`}>
                <img src={path} className='img-fluid w-100' />
              </div>

              <div className="container" >

                <div className='position' data-aos="flip-left" data-aos-duration="1000" >
                  <Item><h1>You Are Invited!</h1>
                  </Item>
                  <Item><h2>The Wedding of</h2>
                  </Item>
                </div>
                <div
                  className='mt-3 nama'
                  data-aos="zoom-in" data-aos-duration="1000"
                >
                  <Item>
                    {inisial_ce}
                  </Item>
                  <Item>
                    &
            </Item>
                  <Item>
                    {inisial_co}
                  </Item>
                </div>
                <div className="w-100 position-absolute" style={{ bottom: '10%', left: 0 }}>
                  <Item>
                    {
                      <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`}
                        style={{ marginTop: '0' }} data-aos="fade-right">
                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                    }</Item>
                  <div className='tombol m-0'>
                    <Item>
                      <button
                        onClick={() => {
                          this.play()
                        }}
                        style={{
                          marginTop: 0
                        }}
                      >Open Invitation</button>
                    </Item>
                  </div>

                </div>
              </div>
            </div>
            <div className={hide ? 'd-none' : 'd-block'}>


              <Container id='sectiongold24' className='pb-md-5' ref={this.myRef}>
                <div className='path w-100' >
                  <img src={path3} className='img-fluid w-100' />
                </div>
                <div className='pt-5 pb-5'>

                  <Item>
                    <Col xs={4} lg={1}>
                      <img src={path2} className='img-fluid w-100' data-aos="fade-left" data-aos-duration="1000" />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center col-md-8 quotes pt-3 pb-md-5' data-aos="fade-left"
                      data-aos-duration="1000">
                      “And of His signs is that He created for you from yourselves mates that youmay
                      find tranquility in them; and He placed between you affection and mercy.Indeed
                  in that are signs for a people who give thought.” <br /><br />(Ar-Rum: 21)
              </p>
                  </Item>

                </div>
              </Container>

              <Container id='mempelai'>
                <Item>
                  <Col xs={12} md={8}>
                    <div className='opening' data-aos="zoom-in">
                      Maha Suci Allah yang telah menciptakan makhluk-Nya secara Berpasang-pasangan.
                      Ya Allah perkenankan kami merangkai kasih sayang yang kau ciptakan diantara Putra Putri kami.
                </div>
                    <Item>
                      <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                        <div className='kotak mr-lg-2'>
                          <Item>
                            <h1>
                              {inisial_ce}
                            </h1>
                          </Item>
                          <Item>
                            <Col xs={6}>
                              <img src={foto_ce} className='img-fluid w-100' />
                            </Col>
                          </Item>
                          <Item>
                            <h1>
                              {lengkap_ce}
                            </h1>
                          </Item>
                          <Item>
                            <p className='text-center'>
                              Putri dari:<br />
                              {bapak_ce}<br />
dan<br />
                              {ibu_ce}<br />
                            </p>
                          </Item>
                          <Item>

                            <img src={logoig}
                              onClick={() => {
                                window.location.href = 'https://instagram.com/' + ig_ce
                              }}
                              className='img-fluid w-100 p-0 btn'
                              style={{ width: '35px', height: '35px' }} />

                          </Item>
                        </div>
                      </div>
                      <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                        <div className='kotak mr-lg-2'>
                          <Item>
                            <h1>
                              {inisial_co}
                            </h1>
                          </Item>
                          <Item>
                            <Col xs={6}>
                              <img src={foto_co} className='img-fluid w-100' />
                            </Col>
                          </Item>
                          <Item>
                            <h1 >
                              {lengkap_co}
                            </h1>
                          </Item>
                          <Item>
                            <p className='text-center'>
                              Putra dari:<br />
                              {bapak_co}<br />
dan<br />
                              {ibu_co}<br />
                            </p>
                          </Item>
                          <Item>
                            <img src={logoig}
                              onClick={() => {
                                window.location.href = `https://instagram.com/${ig_co}`
                              }}
                              className='img-fluid w-100 p-0 btn' style={{ width: '35px', height: '35px' }} />

                          </Item>
                        </div>
                      </div>
                    </Item>
                  </Col>
                </Item>
              </Container>


              <Container id='sectiongold27'>
                <div className='pt-3 ml-3 mr-3'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <Col xs={12} md={6} className='katok'>

                      {
                        acara.map((val, i) => {
                          return (
                            <div data-aos={`fade-${i % 2 == 0 ? 'left' : 'right'}`} data-aos-duration="1000">
                              <Item>
                                <h1>
                                  {val[0]}
                                </h1>
                              </Item>
                              <Item>
                                <p className='bold italic'>
                                  {val[1]}
                                </p>
                              </Item>
                              <Item>
                                <p className='italic'>
                                  {val[2]}
                                </p>
                              </Item>
                              <Item>
                                <p className='mt-2'>
                                  {val[3]}
                                </p>
                              </Item>
                            </div>
                          )
                        })
                      }
                    </Col>
                  </Item>
                  <Item>
                    <div data-aos="zoom-in" data-aos-duration="1000" className='col-6 col-lg-4 mt-3 button text-center'
                      onClick={() => {
                        window.location.href = "https://goo.gl/maps/gfBTo1QNvfkumgrA8"
                      }}>
                      Get Direction
                </div>
                  </Item>
                  <Item>
                    <div data-aos="zoom-in" data-aos-duration="1000" className='btn col-6 col-lg-4 mt-3 button text-center smaller' onClick={() => {
                      window.location.href = "https://calendar.google.com/event?action=TEMPLATE&tmeid=NmJtNWFwNmE1cDczcHFrNW1rMGtyMWkwdW4gYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com"
                    }} >
                      Add to Google Calendar
                </div>
                  </Item>
                  <Container className="text-center dinny py-3" >
                    <Item>
                      <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px', fontFamily: 'Josefin Sans, sans-serif' }}>
                        <h1 className="fs30"
                          style={{

                            fontFamily: 'Adine-Kirnberg-Alternate',
                            color: cmain,
                            fontSize: '30px',
                            fontWeight: 700
                          }}>
                          Protokol Kesehatan

                          </h1>
                        <Item>
                          <Col xs={12}>
                            <p className="cblack text-center w-100 fs16">
                              Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
</p>
                          </Col>

                        </Item>
                        <Item>
                          <Col xs={10}>
                            <Item>
                              <Col xs={6} sm={3}>
                                <img src={pw("reni-shidqi", 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                <Row>
                                  <p className="cblack text-center w-100 fs16">
                                    Gunakan Masker
                                                </p>
                                </Row>
                              </Col>
                              <Col xs={6} sm={3}>
                                <img src={pw("asset", 'wash.svg')} className="w-100 img-fluid p-sm-4" />
                                <Row>
                                  <p className="cblack text-center w-100 fs16">
                                    Cuci Tangan
                      </p>
                                </Row>
                              </Col>
                              <Col xs={6} sm={3}>
                                <img src={pw("reni-shidqi", 'hand.svg')} className="w-100 img-fluid p-sm-4" />
                                <Row>
                                  <p className="cblack text-center w-100 fs16">
                                    Gunakan Handsanitizer
                                                </p>
                                </Row>
                              </Col>
                              <Col xs={6} sm={3}>
                                <img src={pw("reni-shidqi", 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                <Row>
                                  <p className="cblack text-center w-100 fs16">
                                    Jaga Jarak
                      </p>
                                </Row>
                              </Col>

                            </Item>
                          </Col>
                        </Item>
                      </Col>
                    </Item>

                    <Item>
                      <Col>
                      </Col>

                    </Item>

                  </Container>

                  <Item>
                    <div data-aos="fade-left" data-aos-duration="1000" className='col-10 col-lg-8 kotak'>
                      <Item>
                        <div className='item'>
                          <Item>
                            <div>
                              {days}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Days
                      </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {hours}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Hours
                      </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div >
                              {minutes}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Mins
                      </span>
                          </Item>
                        </div>
                        <div className='dot' >:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {seconds}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Secs
                      </span>
                          </Item>
                        </div>
                      </Item>

                    </div>
                  </Item>
                </div></Container>
              <Container className='mt-3' data-aos="fade-right" data-aos-duration="1000">
                <Slider slide={slider} />
              </Container>
              <Container id='sectiongold58' >

                <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                      <Item>
                        <h1 className="w-100 text-center" style={{
                          fontFamily: '"Marck Script", cursive',
                          color: cmain
                        }}>
                          Kirim Ucapan dan Reservasi
                    </h1>
                      </Item>
                      <Item>
                        <form className="col-12 w-100">
                          <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                          <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                          <Item>
                            <div id="formradio">
                              <div class="custom_radio row justify-content-center">
                                <div onClick={() => {
                                  this.setState({ hadir: true })
                                }
                                }>
                                  <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                  <label for="featured-1">Hadir</label>
                                </div>
                                <div onClick={() => {
                                  this.setState({ hadir: false })
                                }
                                } className="pl-5">
                                  <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                  <label for="featured-2"

                                  >Tidak Hadir</label>
                                </div>

                              </div>
                            </div>
                          </Item>
                          {!hadir ? false : (
                            <>
                              <Alert variant='dark col-12 mr-4 '>
                                <p style={{ fontSize: '16px' }} className="w-100 text-center">
                                  Silahkan Pilih Jadwal Kedatangan
                                      </p>
                                <div id="formradio" className='pl-0'>
                                  <div class="custom_radio row justify-content-center">

                                    <div onClick={() => {
                                      this.setState({ sesi: 1 })
                                    }
                                    }
                                      className="pl-5">
                                      <input type="radio" id="featured-3" name="sesi" checked={sesi == 1 ? true : false} />
                                      <label for="featured-3">

                                        Sesi 1 : 12.00 WIB - 15.00 WIB</label>
                                    </div>
                                    <div onClick={() => {
                                      this.setState({ sesi: 2 })
                                    }
                                    } className="pl-5">
                                      <input type="radio" id="featured-4" name="sesi" checked={sesi == 2 ? true : false} />
                                      <label for="featured-4"

                                      >Sesi 2 : 15.00 WIB - 18.00 WIB</label>
                                    </div>


                                  </div>
                                </div>
                                <p style={{ fontSize: '16px' }} className="w-100 text-center">
                                  Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan
                                      </p>
                              </Alert>

                            </>
                          )}

                          <Item>
                            <Col xs={12} className=''>
                              {
                                submitted == true ? (
                                  <Alert variant='success' style={{ fontSize: '16px' }}>
                                    Pesan anda sudah disampaikan
                                  </Alert>) : (submitted === false ? (
                                    <Alert variant='danger' style={{ fontSize: '16px' }}>
                                      {
                                        err.map(val => {
                                          return (
                                            <li>{val}</li>
                                          )
                                        })
                                      }

                                    </Alert>
                                  ) : false)
                              }

                            </Col>
                          </Item>
                          <Item>
                            <div className='col-6 button rounded btn'
                              onClick={() => this.handleSubmit()} style={{ backgroundColor: '#77411A', color: 'white' }} no> Kirim </div>
                          </Item>
                        </form>
                      </Item>
                    </div>
                  </Item>
                </div>
              </Container>
              <Container fluid style={{ backgroundColor: brown, color: 'white', position: 'relative' }} className="py-3 mt-3">
                <Item>
                  <Button style={{ backgroundColor: brown }} onClick={() => {
                    this.setState({ showrek: true })
                  }}>
                    <Item>
                      <p className="px-2 mb-0" style={{ fontSize: '22px' }}>
                        Send Gift <img src='https://www.flaticon.com/svg/static/icons/svg/837/837891.svg' style={{ width: '25px', height: '25px', marginLeft: '10px' }} />

                      </p>
                    </Item>
                  </Button>
                </Item>
                {
                  showrek ? (<>
                    <Item>
                      <p className="text-center mx-5 py-3" style={{ fontSize: '16px' }}>
                        Tanpa mengurangi rasa hormat, bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih untuk pengantin, dapat melalui:
                                    </p>
                    </Item>
                    <Item>

                      <div className="p-3 px-5 col-md-4 col-12 my-2" style={{ backgroundColor: 'white' }}>
                        {/* <Item>
                                            <img src={pw(id,"bca.jpg")} style={{width:'200px',height:'200px'}} className="m-2"/>
                                        </Item> */}
                        <Item>
                          <p className="text-center" style={{ fontSize: '16px', color: brown }}>
                            <b>Account Number 0872 0104 7634 534 </b><br />
                                                Bank BRI <br />
                                                a.n.   WIDYA AKMALINDA
                                            </p>
                        </Item>
                        <Item>
                          <CopyToClipboard text="0872 0104 7634 534 ">
                            <div className="position-relative">

                              <Button style={{ backgroundColor: brown }} onClick={() => {
                                this.setState({ copy: true })
                              }}>
                                <Item>
                                  <p className="px-2 mb-0" style={{ fontSize: '14px', color: 'white' }}>
                                    {this.state.copy ? 'Copied to Clipboard' : 'Copy Account Number'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{ width: '25px', height: '25px', marginLeft: '10px' }} />

                                  </p>
                                </Item>
                              </Button>


                            </div>
                          </CopyToClipboard>
                        </Item>

                      </div>
                      <div className="p-3 px-5 col-md-4 col-12 my-2" style={{ backgroundColor: 'white' }}>
                        {/* <Item>
                                            <img src={pw(id,"bca.jpg")} style={{width:'200px',height:'200px'}} className="m-2"/>
                                        </Item> */}
                        <Item>
                          <p className="text-center" style={{ fontSize: '16px', color: brown }}>
                            <b>Account Number 143 0000 11 3595  </b><br />
                                                Bank Mandiri<br />
                                                a.n.  WIDYA AKMALINDA
                                            </p>
                        </Item>
                        <Item>
                          <CopyToClipboard text="143 0000 11 3595  ">
                            <div className="position-relative">

                              <Button style={{ backgroundColor: brown }} onClick={() => {
                                this.setState({ copy: true })
                              }}>
                                <Item>
                                  <p className="px-2 mb-0" style={{ fontSize: '14px', color: 'white' }}>
                                    {this.state.copy ? 'Copied to Clipboard' : 'Copy Account Number'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{ width: '25px', height: '25px', marginLeft: '10px' }} />

                                  </p>
                                </Item>
                              </Button>
                            </div>
                          </CopyToClipboard>
                        </Item>

                      </div>
                    </Item>
                    <Item>
                      <p className="px-2 mb-0 text-center" style={{ fontSize: '14px', color: 'white' }}>
                        <b>
                          Alamat Pengiriman Kado:<br />
                        </b>
                                                            Bpk. Akmo / Widya&Ardy (083847372202)<br />
Jl. Branjangan Gg. Damai Rt02/Rw10 Desa Sumberlangon, Kel. Slawu, Kec Patrang, Jember
                                                            </p>
                    </Item>

                  </>) : false}
              </Container>

              <Container id='sectiongold210' >
                <div className='path w-100 '>
                  <img src={path3} className='img-fluid w-100 ' />
                </div>
              </Container>

              <Container fluid style={{ backgroundColor: 'white' }}>
                <p style={{color:'black',fontFamily:"Marck Script, cursive"}} className="w-100 text-center">
                "Terima Kasih"<br/>
                Widya & Ardy
                </p>
                <Foot ig={logoig} dark />
              </Container>

            </div>
          </div>
        </div>
      </>


    )
  }
}